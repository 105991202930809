import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = ["field", "localization", "unit", "fuelYear", "fuelCountry",
    "fuelCurrency", "idBaseCarbone", "standardFields", "expensesFields"]
  static values = { emissionItem: String, reportYear: String }

  yearInput = "activity_datum[fuel_year]";

  buildData(element) {
    if (element.dataset.targetAttribute.match(/year|currency|country/)) {
      return this.buildFuelStatData(element);
    }
    const data = super.buildData(element)['emission_factor'];
    const dataSource = document.querySelector("[name='activity_datum[data_source]']:checked").value;
    if (dataSource === 'fuel_expenses') {
      data['fuel_expenses'] = true;
    }
    return { 'emission_factor': data };
  }

  buildFuelStatData (element) {
    const data = {
      id_base_carbone: this.idBaseCarboneTarget.value,
      year: this.fuelYearTarget.value,
      country: this.fuelCountryTarget.value,
      currency: this.fuelCurrencyTarget.value,
      target_attribute: element.dataset.targetAttribute
    };
    return { 'fuel_stat': data };
  }

  refreshForm(event) {
    this.fieldTargets[0].dispatchEvent(new Event('change'));
    const dataSource = event.currentTarget.value;
    this.toggleFields(dataSource);
  }

  nextInput(element) {
    // Find the next input based on an element's target_attribute dataset.
    const targetAttribute = element.dataset.targetAttribute;
    if (targetAttribute.match(/year|currency|country|id_base_carbone/)) {
      return this.nextFuelInput(targetAttribute);
    } else if (!element.dataset.targetAttribute) {
      return null;
    }
    return super.nextInput(element);
  }

  nextFuelInput(targetAttribute) {
    switch (targetAttribute) {
      case "id_base_carbone":
        return this.idBaseCarboneTarget;
      case "year":
        return this.fuelYearTarget;
      case "country":
        return this.fuelCountryTarget;
      case "currency":
        return this.fuelCurrencyTarget;
      default:
        return null;
    };
  }

  replaceOptions (element = null, nextInput, options) {
    if (nextInput === this.fuelYearTarget) {
      return this.replaceOptionsAndHandleReportYear(element, nextInput, options);
    }
    return super.replaceOptions(element, nextInput, options);
  }

  toggleFields(dataSource) {
    if (dataSource === "fuel_expenses") {
      this.standardFieldsTarget.querySelectorAll('input, select').forEach(field => field.disabled = true);
      this.expensesFieldsTarget.querySelectorAll('input, select').forEach(field => field.disabled = false);
    } else {
      this.standardFieldsTarget.querySelectorAll('input, select').forEach(field => field.disabled = false);
      this.expensesFieldsTarget.querySelectorAll('input, select').forEach(field => field.disabled = true);
    }
  }
}

import BaseController from "./base_controller";
import $ from "jquery"

export default class extends BaseController {
  static targets = ["field", 'localization', 'unit', "base", "headcount", "value1", "value2", "valueLabel"]

  addPlaceholder = (element, nextInput, options) => {
    let placeholder = nextInput.dataset.placeholder;
    let string = placeholder;
    if (placeholder && placeholder !== "false" && options.length > 1) {
      // If passing JSON instead of a string
      if (placeholder[0] === "{") {
        placeholder = JSON.parse(placeholder);
        if (placeholder[element.value]) {
          string = placeholder[element.value];
        } else {
          string = placeholder["default"];
        }
      } else {
        string = placeholder;
      }
      options.unshift(string);
    }
    return options
  }

  replaceValueLabel = (response) => {
    if (!this.hasValueLabelTarget) { return }

    const unit = response[0][1];
    const translations = I18n?.controllers?.dropdowns?.transport?.value_1
    if (unit.match(/heure/)) {
      this.valueLabelTarget.innerHTML = translations.label.duration;
    } else if (unit.match(/km/)) {
      this.valueLabelTarget.innerHTML = translations.label.distance;
    } else {
      this.valueLabelTarget.innerHTML = translations.label.other;
    }
  }

  replaceValuePlaceholder = (response, nextInputIndex) => {
    if (nextInputIndex === "1" && !this.hasValue1Target) { return }
    if (nextInputIndex === "2" && !this.hasValue2Target) { return }

    const valueTarget = nextInputIndex === "1" ? this.value1Target : this.value2Target;
    const unit = response[0][1];
    const translations = I18n?.controllers?.dropdowns?.transport?.value_1

    if (unit.match(/heure/)) {
      valueTarget.placeholder = translations.placeholder.duration;
    } else if (unit.match(/km/)) {
      valueTarget.placeholder = translations.placeholder.distance;
    } else if (unit.match(/passager/)) {
      valueTarget.placeholder = translations.placeholder.passenger;
    } else {
      valueTarget.placeholder = translations.placeholder.other;
    }
  }

  fetchOptions = (url, element) => {
    const that = this;
    $.ajax({
      url: url,
      async: false,
      dataType: 'json',
      type: 'GET',
      headers: { 'X-CSRF-Token':  document.querySelector('meta[name="csrf-token"]')?.content },
      data: this.buildData(element),
      success: (response) => {
        const nextInput = that.nextInput(element);
        if (!nextInput) {
          return;
        // If there are multiple options and the next input is not a unit
        } else if (nextInput && response.length > 1 && response[0] !== "-" && !this.unitTargets.includes(nextInput)) {
          this.replaceOptions(element, nextInput, response)
          this.reveal(nextInput);
        // If the next input is a unit
        } else if (nextInput && this.unitTargets.includes(nextInput)) {
          // If it's data_unit_1, update label and placeholder, update the unit options and load the next input
          const nextInputIndex = nextInput.dataset.index;
          this.replaceValueLabel(response);
          this.replaceValuePlaceholder(response, nextInputIndex);
          if (nextInputIndex === "1") {
            this.replaceOptions(element, nextInput, response)
            this.loadData(nextInput);
          // If it's data_unit_2 and a second unit is returned, load it and display both the value field and the unit input
          } else if (nextInput.dataset.index === "2" && !response[0].includes("-")) {
            this.replaceOptions(element, nextInput, response)
            this.headcountTarget.classList.remove('d-none');
            this.headcountTarget.querySelectorAll('input, select').forEach(input => input.disabled = false)
            this.headcountTarget.querySelectorAll('input[value="0.0"]').forEach(input => input.value = "")
          // Otherwise hide and disable both the unit input and the value field
          } else {
            this.headcountTarget.classList.add('d-none');
            this.headcountTarget.querySelectorAll('input').forEach(input => input.value = "")
            this.headcountTarget.querySelectorAll('select option').forEach(option => option.selected = false)
          }
        // If there is only one option and it's not a unit
        } else if (nextInput && response[0] !== "-") {
          this.replaceOptions(element, nextInput, response)
          // If it's empty, hide it
          if (response.length === 0 || response[0].includes("-")) {
            this.hide(nextInput);
          // Otherwise display it (in other sections, this field would remain hidden by default)
          } else {
            this.reveal(nextInput);
          }
          // Then load the next input automatically
          this.loadData(nextInput);
        // Otherwise just load the next input automatically
        } else {
          this.loadData(nextInput);
        }
      }
    });
  }
}

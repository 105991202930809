import BaseController from "./base_controller";

export default class extends BaseController {
  static values = { emissionItem: String, reportYear: String }

  yearInput = "activity_datum[ef_level_2]";

  clearFieldsAfterElement = (element) => {
    if (this.localizationTargets.includes(element)) {
      const nextInput = this.fieldTargets.find(target => target.dataset.index === "2");
      nextInput.options.length = 0;
      nextInput.innerHTML = '<option selected value="-">-</option>';
    }
  }

  replaceOptions = this.replaceOptionsAndHandleReportYear;
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "selectedFileInfos"];

  preventDefaultDrop(event) {
    event.preventDefault()
  }

  handleDrop(event) {
    event.preventDefault()
    const files = event.dataTransfer.files
    this.setSelectedFiles(files)
    this.displaySelectedFile(files[0])
  }

  openFileDialog() {
    this.inputTarget.click()
  }

  handleDialogSelect(event) {
    const file = event.target.files[0]
    this.displaySelectedFile(file)
  }

  // private

  setSelectedFiles(files) {
    this.inputTarget.files = files
    this.inputTarget.dispatchEvent(new Event('change'));
  }

  displaySelectedFile(file) {
    this.selectedFileInfosTarget.innerText = file.name
  }
}
